.button {
    padding: 10px;
    width: 250px;
    height: 50px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    z-index: 5;
    margin-top: 10px;
    color: #ffffff;
}

.button:hover {
    opacity: 0.8;
}

.productCard {
    width: 315px;
    height: 450px;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 2rem;
    margin: 25px;
}

.header {
    font-size: 24px;
}

.content {
    font-size: 18px;
    color: #5E5E5E
}

.testimonialCard {
    border-radius: 8px;
    box-shadow: 0 0 10px #cdcdcd;
    max-width: 521px;
    min-height: 300px;
    margin-top: -15px;
    background-color: #ffffff;
    align-items: center;
}

.testimonialContent {
    display: flex;
    padding: 10p;
    flex-direction: column;
    align-items: flex-start;
}

.carousel {
    margin: 0 auto;
    padding: 20px 90px;
    max-width: 1640px;
}

.carousel :global(.rec-carousel-item) {
    margin: auto 0;
}

.carousel :global(button.rec-arrow) {
    background-color: rgb(185, 185, 185);
}

.carousel :global(button.rec-arrow:hover:not(:disabled)),
.carousel :global(button.rec-arrow:focus:not(:disabled)) {
    background-color: #CF0032;
}

.carousel :global(.rec-pagination) {
    padding: 20px 0;
}

.carousel :global(button.rec-dot_active) {
    background-color: #CF0032;
    box-shadow: 0 0 1px 3px #CF0032;
}

.carousel :global(button.rec-dot:hover),
.carousel :global(button.rec-dot:active),
.carousel :global(button.rec-dot:focus) {
    box-shadow: 0 0 1px 3px #CF0032;
}

@media only screen and (max-width: 600px) {
    .testimonialCard {
        width: auto;
        height: auto;
    }

    .carousel {
        margin: 0 auto;
        padding: 20px 0px;
    }
}

@media only screen and (max-width: 480px) {
    .testimonialCard {
        width: auto;
        height: auto;
    }

    .productCard {
        width: 260px
    }

    .button {
        width: 200px;
    }

    .carousel {
        margin: 0 auto;
        padding: 20px 0px;
    }
}